import React from "react";
import DasKaltWarmeBuffet from "../Allmenus/Thrdmenu/DasKaltWarmeBuffet";
import DasKalteBuffet from "../Allmenus/Thrdmenu/DasKalteBuffet";
import DasMenuIndividuell from "../Allmenus/Thrdmenu/DasMenuIndividuell";
import Privateevent from "../Allmenus/Thrdmenu/Privateevent";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/background/banner-image-2.jpg";
import Contact from "../Menhome/Contact";
import CocktailsImKristallGlas from "../Allmenus/Thrdmenu/CocktailsImKristallGlas";
import DasMenu from "../Allmenus/Thrdmenu/DasMenu";

export default function BuffetPage() {
    return (
        <>
            <Bredcrumb
                subtitle="Buffet"
                title="Erstklassige Auswahl"
                Img={Img}
            />
            <DasKalteBuffet />
            <DasKaltWarmeBuffet />
            <DasMenuIndividuell />
            <CocktailsImKristallGlas />
            <DasMenu />
            <Privateevent />
            <Contact />
        </>
    );
}
