import React from "react";
import Privateevent from "../Allmenus/Thrdmenu/Privateevent";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/background/banner-image-2.jpg";
import Contact from "../Menhome/Contact";
import Fruehstueck from "../Allmenus/Thrdmenu/Fruehstueck";

export default function FruehstueckPage() {
    return (
        <>
            <Bredcrumb
                subtitle="Frühstück"
                title="Erstklassige Auswahl"
                Img={Img}
            />
            <Fruehstueck />
            <Privateevent />
            <Contact />
        </>
    );
}
