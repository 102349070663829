import React from "react";
import { Link } from "react-router-dom";
import featured8 from "../../../assets/images/resource/private-event.jpg";
import featured10 from "../../../assets/images/resource/fingerfood.jpg";

function Privateevent() {
    return (
        <>
            <section className="featured-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-lg-5 col-md-12 col-sm-12">
                            <div
                                className="inner wow fadeInLeft"
                                data-wow-duration="1500ms"
                                data-wow-delay="0ms"
                            >
                                <div className="content">
                                    <div className="content-inner">
                                        <h2>Firmen Events</h2>
                                        <div className="text"></div>
                                        <div className="separator">
                                            <span></span>
                                        </div>
                                        <div className="booking-info">
                                            <div className="bk-title">
                                                Kontakt
                                            </div>
                                            <div className="bk-no">
                                                <Link to="tel:02129 3788003">
                                                    02129 3788003
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="link-box">
                                            <Link
                                                to="#"
                                                className="theme-btn btn-style-one clearfix"
                                            >
                                                <span className="btn-wrap">
                                                    <span className="text-one">
                                                        Kontakt
                                                    </span>
                                                    <span className="text-two">
                                                        Kontakt
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div
                                className="inner wow fadeInRight"
                                data-wow-duration="1500ms"
                                data-wow-delay="0ms"
                            >
                                <div className="row clearfix">
                                    <div className="img-col col-lg-12 col-md-12 col-sm-12">
                                        <div className="image">
                                            <img src={featured8} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="featured-section alternate">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-lg-5 col-md-12 col-sm-12">
                            <div
                                className="inner wow fadeInRight"
                                data-wow-duration="1500ms"
                                data-wow-delay="0ms"
                            >
                                <div className="content">
                                    <div className="content-inner">
                                        <h2>Private Events</h2>
                                        <div className="text"></div>
                                        <div className="separator">
                                            <span></span>
                                        </div>
                                        <div className="booking-info">
                                            <div className="bk-title">
                                                Kontakt
                                            </div>
                                            <div className="bk-no">
                                                <Link to="tel:02129 3788003">
                                                    02129 3788003
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="link-box">
                                            <Link
                                                to="#"
                                                className="theme-btn btn-style-one clearfix"
                                            >
                                                <span className="btn-wrap">
                                                    <span className="text-one">
                                                        Kontakt
                                                    </span>
                                                    <span className="text-two">
                                                        Kontakt
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div
                                className="inner wow fadeInLeft"
                                data-wow-duration="1500ms"
                                data-wow-delay="0ms"
                            >
                                <div className="row clearfix">
                                    <div className="img-col col-lg-12 col-md-12 col-sm-12">
                                        <div className="image">
                                            <img src={featured10} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Privateevent;
