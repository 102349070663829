import React from "react";
import bg19 from "../../../assets/images/background/bg-19.png";

function DasKaltWarmeBuffet() {
    return (
        <>
            <section className="menu-two">
                <div className="right-bg">
                    <img src={bg19} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle">
                            <span>Das Zeichen für Geschmack</span>
                        </div>
                        <div className="pattern-image">
                            <img
                                src={
                                    require("../../../assets/images/icons/separator.svg")
                                        .default
                                }
                                alt="mySvgImage"
                            />
                        </div>
                        <h2>Das Kalt-Warme Buffet</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    200
                                                    <span className="s-info">
                                                        ab 15 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€39,00 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            <strong>KALT</strong>
                                            <br />
                                            Bunte Schwedenplatte mit pochierter
                                            <br />
                                            Lachsforelle, echtem Lachs,
                                            Räucheraal,
                                            <br />
                                            Forellenfilets, Sahnemeerrettich
                                            <br />
                                            Kalbsbraten mit Gemüse dekoriert
                                            <br />
                                            Roastbeef medium, Pfeffersauce
                                            <br />
                                            Gefüllte Artischocken mit Spargel
                                            <br />
                                            Waldorfsalat, Spargelsalat
                                            <br />
                                            Eisbergsalat mit Cocktailsauce
                                            <br />
                                            Diplomatensalat
                                            <br />
                                            <br />
                                            <strong>WARM</strong>
                                            <br />
                                            Putenfilet mit Spinat
                                            <br />
                                            Bunten Nudeln und Sauce Bernaise
                                            <br />
                                            <br />
                                            <strong>DESSERT</strong>
                                            <br />
                                            exotischer Obstsalat
                                            <br />
                                            Französische Käseecken, Weintrauben
                                            <br />
                                            Bunter Brotkorb, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    201
                                                    <span className="s-info">
                                                        ab 20 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€19,80 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            <strong>
                                                Panierter Putenschnitzel, warm
                                                auf Holzbrett serviert zum
                                                Selbstaufschneiden
                                            </strong>
                                            <br />
                                            (Tranchierbesteck wird gestellt)
                                            <br />
                                            Mixed Pickles, Tomate, Pfeffersauce
                                            <br />
                                            Gratinkartoffeln
                                            <br />
                                            Krautsalat, frische
                                            Salatplatte/Dressing
                                            <br />
                                            Kartoffelsalat, Bauernsalat
                                            <br />
                                            Gervaise-Birnen mit Früchten
                                            <br />
                                            Bauernbrotkorb mit Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    202
                                                    <span className="s-info">
                                                        ab 20 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€15,50 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            <strong>KALT</strong>
                                            <br />
                                            Verschiedene Wurstspezialitäten.
                                            Pute Hähnchen, Rind
                                            <br />
                                            Kleine Frikadellen mit Senf
                                            <br />
                                            Gefüllte Birnen und Pfirsiche
                                            <br />
                                            Matjestopf
                                            <br />
                                            Kartoffelsalat
                                            <br />
                                            Krautsalat
                                            <br />
                                            Bunter Nudelsalat
                                            <br />
                                            Verschiedene Saucen
                                            <br />
                                            Käseauswahl, Salzbrezel
                                            <br />
                                            Brot und Butter
                                            <br />
                                            <br />
                                            <strong>WARM</strong>
                                            <br />
                                            Kartoffelgratin
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    203
                                                    <span className="s-info">
                                                        ab 10 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€29,40 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            <strong>BUNTE FISCHPLATTE</strong>
                                            mit echtem Lachs,
                                            <br />
                                            Räucheraal, Forellenfilets
                                            <br />
                                            Matjesröllchen, garniert mit Ei,
                                            Zitrone,
                                            <br />
                                            Sahnemeerrettich
                                            <br />
                                            Gervaisebirnen
                                            <br />
                                            Pfeffersauce
                                            <br />
                                            Gegrillte Putenbrust, Roastbeef
                                            medium
                                            <br />
                                            Frische Salate mit French Dressing
                                            <br />
                                            Rindfleischsalat, Bauernsalat
                                            <br />
                                            Französische Käseauswahl,
                                            Weintrauben
                                            <br />
                                            Salzbrezeln
                                            <br />
                                            Brotkorb, Butter
                                            <br />
                                            <br />
                                            <strong>DESSERT</strong>
                                            <br />
                                            Mohnmousse
                                            <br />
                                            <br />
                                            <strong>
                                                Dieses Programm ist auch ohne
                                                Fischplatte lieferbar:
                                                <br />
                                                €22,20 / Person
                                            </strong>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    204
                                                    <span className="s-info">
                                                        ab 20 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€27,00 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            <strong>KALT</strong>
                                            <br />
                                            Rauchfischplatte
                                            <br />
                                            mit Räucheraal, Forellenfilets,
                                            Makrelen
                                            <br />
                                            und Kieler Sprotten, Sahnemeerretich
                                            <br />
                                            Crevettenereier
                                            <br />
                                            Bunte Bratenplatte mit Roastbeef,
                                            <br />
                                            Putenbrust
                                            <br />
                                            Drei diverse Salate
                                            <br />
                                            <br />
                                            <strong>WARM</strong>
                                            <br />
                                            Hähnchenfiletspitzen in Kräutersauce
                                            <br />
                                            Patna-Reis <br />
                                            Internationale Käseauswahl <br />
                                            Brot und Butter <br />
                                            <br />
                                            <strong>DESSERT</strong>
                                            <br />
                                            Vanille-Quark-Creme mit Fruchtsauce
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    205
                                                    <span className="s-info">
                                                        ab 15 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€44.30 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            <strong>KALT</strong>
                                            <br />
                                            Räucherlachs
                                            <br />
                                            Fischterrine mit Kräutersauce,
                                            Heilbutt
                                            <br />
                                            Gegrillte Garnelen mit Kräutern
                                            <br />
                                            Entenbrust
                                            <br />
                                            Schottisches Roastbeef medium
                                            <br />
                                            Rinderschinken Melone und Feigen
                                            <br />
                                            Diverse Saucen
                                            <br />
                                            Zuckerschotensalat, Champignonsalat
                                            <br />
                                            <br />
                                            <strong>WARM</strong>
                                            <br />
                                            Rinderfiletspitzen in
                                            Pommery-Senfsauce,
                                            <br />
                                            Brokkoliröschen, Kartoffelgratin
                                            <br />
                                            <br />
                                            <strong>DESSERT</strong>
                                            <ul>
                                                <li>Bananencreme mit Feigen</li>
                                            </ul>
                                            <br />
                                            <strong>SAUCEN</strong>
                                            Fruchtsalat
                                            <br />
                                            Bananen-Kokosnuß-Creme mit
                                            Pfirsischsauce
                                            <br />
                                            Französische Käseauswahl, Brot und
                                            Butter
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    206
                                                    <span className="s-info">
                                                        ab 15 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€33,00 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            <strong>KALT</strong>
                                            <br />
                                            Pochierte Lachforelle,
                                            Dillmayonnaise
                                            <br />
                                            Seezungenröllchen, grüne Sauce
                                            <br />
                                            Gebeizter Lachs, Senfsauce
                                            <br />
                                            Gefüllte Eier mit Crevetten
                                            <br />
                                            Rinderfiletbraten mit Pfirsichen
                                            <br />
                                            Hähnchenbrustfile
                                            <br />
                                            Diverse Salate nach Ihrer Wahl
                                            <br />
                                            <br />
                                            <strong>WARM</strong>
                                            <br />
                                            Putenfilet
                                            <br />
                                            Champignon-Kräutersauce,
                                            Kartoffelgratin
                                            <br />
                                            <br />
                                            Französische Käseauswahl
                                            <br />
                                            Brotkorb und Butter
                                            <br />
                                            <br />
                                            <strong>DESSERT</strong>
                                            <br />
                                            Bayerische Creme mit Beerenfrüchten
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    207
                                                    <span className="s-info">
                                                        ab 10 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€29,40 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            <strong>KALT</strong>
                                            <br />
                                            Forellenfilet mit Sahnemeerrettich
                                            <br />
                                            Räucherlachs
                                            <br />
                                            Roastbeef medium mit Maiskolben
                                            <br />
                                            Hähnchenbrust mit Champignons
                                            <br />
                                            Putenbrust mit Melonenperlen
                                            <br />
                                            Gervaise-Birnen
                                            <br />
                                            Crevettensalat, mit
                                            Artischockenherzen
                                            <br />
                                            Salatplatte mit frischen Salaten der
                                            Saison
                                            <br />
                                            French Dressing
                                            <br />
                                            Waldorfsalat
                                            <br />
                                            <br />
                                            <strong>WARM</strong>
                                            <br />
                                            Rindergeschnetz, Spätzle
                                            <br />
                                            Käseecken, Brotkörbchen und Butter
                                            <br />
                                            <br />
                                            <strong>DESSERT</strong>
                                            <br />
                                            Mandelcreme
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    209
                                                    <span className="s-info">
                                                        ab 25 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€33,90 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            <strong>KALT</strong>
                                            <br />
                                            Rauchfischplatte mit geräuchertem
                                            <br />
                                            Lachs, Forellenfilet,
                                            Seezungenröllchen
                                            <br />
                                            Sahnemeerrettich
                                            <br />
                                            Gebratene Hühnerbrüstchen mit Ananas
                                            <br />
                                            Kalbsbraten mit Palmenherzen
                                            <br />
                                            Farmsalat, Bauernsalat, Maissalat
                                            <br />
                                            Frischer Salat der Saison
                                            <br />
                                            Diverse Saucen
                                            <br />
                                            <br />
                                            <strong>WARM</strong>
                                            <br />
                                            Gebratene Ochsenkeule mit Kräutern
                                            <br />
                                            der Provence, Gratinkartoffeln
                                            <br />
                                            <br />
                                            Käseauswahl, Brot und Butter
                                            <br />
                                            <strong>DESSERT</strong>
                                            <br />
                                            Grießflammerie mit Himbeersauce
                                            <br />
                                            Exotische Fruchtspeise
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    210
                                                    <span className="s-info">
                                                        ab 25 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€41,90 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            <strong>KALT</strong>
                                            <br />
                                            Bunte Fischplatte mit pochierte
                                            Lachs,
                                            <br />
                                            Forelle, Graved Lachs, Forellenfilet
                                            <br />
                                            Seezungenröllchen, Sahnemeerretttich
                                            <br />
                                            Senfsauce
                                            <br />
                                            Rehrücken garniert, Sauce Cumberland
                                            <br />
                                            Medaillons, gefüllte Birnen,
                                            <br />
                                            gebratene Fasanenbrüstchen,
                                            Kalbsbraten
                                            <br />
                                            verschiedene Saucen
                                            <br />
                                            <br />
                                            <strong>WARM</strong>
                                            <br />
                                            Kalbsrücken, gefüllt mit Blattspinat
                                            <br />
                                            Kräutersauce, Spätzle
                                            <br />
                                            <br />
                                            <strong>ODER</strong>
                                            Lammkeule, Gratinkartoffeln
                                            <br />
                                            Frische Champignons
                                            <br />
                                            <br />
                                            Waldorfsalat, Brokkolisalat,
                                            Bauernsalat
                                            <br />
                                            <strong>DESSERT</strong>
                                            <br />
                                            Obstsalat, Mohnmousse mit
                                            Himbeersauce
                                            <br />
                                            <br />
                                            Brot und Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    211
                                                    <span className="s-info">
                                                        ab 20 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€37,80 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            <strong>KALT</strong>
                                            <br />
                                            Fischplatte mit echtem Lachs
                                            <br />
                                            Forellenfilet, pochierte
                                            Lachsforelle,
                                            <br />
                                            Seezungenröllchen, Sahnemeerrettich
                                            <br />
                                            Senfsauce
                                            <br />
                                            Roastbeef, Kalbsbraten, gebratene
                                            <br />
                                            Hühnerbrüstchen, gefüllte
                                            Gervaisebirnen
                                            <br />
                                            <br />
                                            Waldorfsalat, Crevettensalat,
                                            Bauernsalat,
                                            <br />
                                            frische Salatplatte, French Dressing
                                            <br />
                                            Geflügelsalat
                                            <br />
                                            <br />
                                            <strong>WARM</strong>
                                            <br />
                                            Putengeschnetzeltes
                                            <br />
                                            In Kräuterrahm, grüne Bandnudeln
                                            <br />
                                            <br />
                                            Französische Käseauswahl,
                                            Weintrauben
                                            <br />
                                            <strong>DESSERT</strong>
                                            <br />
                                            Gefüllte Crepes mit Früchtequark,
                                            <br />
                                            Pfirsischsauce, Roe Grütze,
                                            Vanillesauce
                                            <br />
                                            <br />
                                            Brot und Butter
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DasKaltWarmeBuffet;
