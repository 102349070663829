import React from "react";
import bg19 from "../../../assets/images/background/bg-19.png";

function DasMenu() {
    return (
        <>
            <section id="snacks-im-kristallglas" className="menu-two">
                <div className="right-bg">
                    <img src={bg19} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle">
                            <span>ab 10 Personen</span>
                        </div>
                        <div className="pattern-image">
                            <img
                                src={
                                    require("../../../assets/images/icons/separator.svg")
                                        .default
                                }
                                alt="mySvgImage"
                            />
                        </div>
                        <h2>Das Menü</h2>
                        <p>
                            🇫🇷 Die Zubereitung erfolgt nach Internationalen
                            Rezepten, insbseondere der französischen Küche. 🇫🇷
                        </p>
                        <p>
                            Besprechen Sie mit uns die Zusammenstellung Ihres
                            Menüs. Wir erfüllen gerne Ihre Wünsche.
                        </p>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Menü 1</h5>
                                            </div>
                                            <div className="price">
                                                <span>€23,40 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Räucherlachsteller mit
                                            Schnittlauchdipp
                                            <br />
                                            Rinderburgunderbraten
                                            <br />
                                            Bohnenbündchenn
                                            <br />
                                            Gratinkartoffelnn
                                            <br />
                                            Kl. Salatteller / Dressingn
                                            <br />
                                            Bayerische Haselnuss creme
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Menü 2</h5>
                                            </div>
                                            <div className="price">
                                                <span>€24,60 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Melone mi Rinderschinken
                                            <br />
                                            Poulardenbrust in Nußsauce
                                            <br />
                                            Brokkoliröschen mit Sauce
                                            Hollondais,
                                            <br />
                                            Butterreis
                                            <br />
                                            Kl. Gemischter Salateller / Dressing
                                            <br />
                                            Malagacreme
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Menü 3</h5>
                                            </div>
                                            <div className="price">
                                                <span>€32,30 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Cocktail von Grönlandcrevetten
                                            <br />
                                            mit Ananas und Orangen in
                                            Cognacsauce
                                            <br />
                                            Geschnetzeltes Rindfleisch in
                                            Kräuterrahm
                                            <br />
                                            Französisches Buttergemüse
                                            <br />
                                            Grüne Bandnudeln
                                            <br />
                                            Eichblattsalat / Dressing
                                            <br />
                                            Gefüllte Crepes mit Karamelsauce
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Menü 4</h5>
                                            </div>
                                            <div className="price">
                                                <span>€33,50 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Geräuchertes Forellenfilet
                                            <br />
                                            Pistazien-Meerrettichsahne
                                            <br />
                                            Geflügel-Consomme
                                            <br />
                                            Blanquet vom Kalb mit Champignons
                                            <br />
                                            Gemüseplatte – Mandelreis
                                            <br />
                                            Feldsalat mit Orangensreifen
                                            <br />
                                            Kalte Zabaionecreme mit Früchten
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Menü 5</h5>
                                            </div>
                                            <div className="price">
                                                <span>€37,80 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Hausgebeizter Lachs, Dill-Senf-Sauce
                                            <br />
                                            Sauerampfercremesuppe mit Croutons
                                            <br />
                                            Navarin vom Lamm
                                            <br />
                                            (fein gewürfelte Lammkeule in
                                            Bordeauxsauce),
                                            <br />
                                            Räucherspeck, Grainkartoffeln
                                            <br />
                                            Grütze von Waldbeeren, Vanillesauce
                                            und Zimsahne
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Menü 6</h5>
                                            </div>
                                            <div className="price">
                                                <span>€43,00 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Geflügelpastete mit Cumberlandsauce
                                            <br />
                                            Lottemedaillons in Champagnersauce
                                            <br />
                                            Petersilienkaroffeln,
                                            <br />
                                            Salat der Saison mit Sauce
                                            Vinaigrette
                                            <br />
                                            Mousse au Chocolat mit Eierlikör
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Menü 7</h5>
                                            </div>
                                            <div className="price">
                                                <span>€42,60 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Wildterrine mit eingelegen
                                            Waldpilzen,
                                            <br />
                                            Sauce Oxford
                                            <br />
                                            Fasanenconsomme mit Kräuterklößchen
                                            <br />
                                            Rehkeule in Wacholderrahm
                                            <br />
                                            Wildspreiselbeeren, Rosenkohl,
                                            Spätzle <br />
                                            Mandelcreme mit Amaretto
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Menü 8</h5>
                                            </div>
                                            <div className="price">
                                                <span>€59,40 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Entenbrustfächer mit Zuckerschoten,{" "}
                                            <br />
                                            Orangen-Apfelsalat /
                                            Walnußöldressing
                                            <br />
                                            Klare Tomatensuppe mit Käsenocken
                                            <br />
                                            und frischem Basilikum
                                            <br />
                                            Seezungenröllchen in Dillrahm
                                            <br />
                                            Zitronensorbet mit Blue Curacao
                                            <br />
                                            Charolais-Rinderfiletbraten
                                            <br />
                                            Sauce Bearnaise, Kartoffel-Gratin
                                            <br />
                                            Eisbergsalat
                                            <br />
                                            mit „Thousand-Island“ – Dressing
                                            <br />
                                            Früchtemosaik mit Mohnmousse
                                            <br />
                                            in Cassissauce
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p style={{ textAlign: "center" }}>
                        Zu den Menüs 1 und 3 bis 8 reichen wir kleine Brötchen
                        oder geschnittenes Stangenbrot
                    </p>
                </div>
            </section>
        </>
    );
}

export default DasMenu;
