import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-white.png";

function Main() {
    const [active, setActive] = useState();
    const [lastscrool, setlastscrool] = useState(0);

    const [scrolled, setScrolled] = useState(false);
    const [Nav, setNav] = useState(true);

    const handleScroll = () => {
        const offset = window.scrollY;
        setlastscrool(offset);

        if (offset > 1000 && offset < lastscrool) {
            setNav(true);
            setScrolled(true);
        } else if (offset > 1000) {
            setNav(false);
        } else if (offset > 200) {
            setNav(true);
            setScrolled(true);
        } else {
            setScrolled(false);
            setNav(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    });

    return (
        <>
            {active && (
                <div
                    className="menu-backdrop"
                    style={{ opacity: "1", visibility: "visible" }}
                ></div>
            )}
            <section className={`hidden-bar ${active && "visible-sidebar"}`}>
                <div className="inner-box">
                    <div
                        className="cross-icon hidden-bar-closer"
                        onClick={() => setActive(false)}
                    >
                        <span
                            className="far fa-close"
                            onClick={() => setActive(false)}
                        >
                            {" "}
                        </span>
                    </div>
                    <div className="logo-box">
                        <Link to="/" title="Party Richter Bala">
                            <img src={logo} alt="" title="Party Richter Bala" />
                        </Link>
                    </div>

                    <div className="side-menu">
                        <ul className="navigation clearfix">
                            <li>
                                <Link to="/">Startseite</Link>
                            </li>
                            <li>
                                <Link to="/#ueber-uns">Über uns</Link>
                            </li>
                            <li>
                                <Link to="/buffet">Buffet</Link>
                            </li>
                            <li>
                                <Link to="/a-la-carte">Á la carte</Link>
                            </li>
                            <li>
                                <Link to="/stehconvent">Stehconvent</Link>
                            </li>
                            <li>
                                <Link to="/fruehstueck">Frühstück</Link>
                            </li>
                            <li>
                                <Link to="/kontakt">Kontakt</Link>
                            </li>
                        </ul>
                    </div>

                    <h2>Kontakt</h2>
                    <ul className="info">
                        <li>Bahnhofstraße 70, 42781 Haan</li>
                        <li>Büro: 9:00 Uhr bis 13:00 Uhr</li>
                        <li>
                            <Link to="mailto:info@party-richter-bala.de">
                                info@party-richter-bala.de
                            </Link>
                        </li>
                    </ul>
                    <div className="separator">
                        <span></span>
                    </div>
                    <div className="booking-info">
                        <div className="bk-title">Anfragen</div>
                        <div className="bk-no">
                            <Link to="tel:02129 3788003">02129 3788003</Link>
                        </div>
                    </div>
                </div>
            </section>
            {Nav && (
                <header
                    className={`main-header ${
                        scrolled && "fixed-header"
                    } header-down`}
                >
                    <div className="header-top">
                        <div className="auto-container">
                            <div className="inner clearfix">
                                <div className="top-left clearfix">
                                    <ul className="top-info clearfix">
                                        <li>
                                            <i className="icon far fa-map-marker-alt"></i>{" "}
                                            Bahnhofstraße 70, 42781 Haan
                                        </li>
                                        <li>
                                            <i className="icon far fa-clock"></i>{" "}
                                            Büro 9:00 Uhr bis 13:00 Uhr
                                        </li>
                                    </ul>
                                </div>
                                <div className="top-right clearfix">
                                    <ul className="top-info clearfix">
                                        <li>
                                            <Link to="tel:+11234567890">
                                                <i className="icon far fa-phone"></i>{" "}
                                                02129 3788003
                                            </Link>{" "}
                                        </li>
                                        <li>
                                            <Link to="mailto:info@party-richter-bala.de">
                                                <i className="icon far fa-envelope"></i>{" "}
                                                info@party-richter-bala.de
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-upper header-fixed">
                        <div className="auto-container">
                            <div className="main-box d-flex justify-content-between align-items-center flex-wrap">
                                <div className="links-box clearfix">
                                    <div className="nav-toggler">
                                        <button className="hidden-bar-opener">
                                            <span
                                                className="hamburger"
                                                onClick={() => setActive(true)}
                                            >
                                                <span className="top-bun"></span>
                                                <span className="meat"></span>
                                                <span className="bottom-bun"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div className="logo-box">
                                    <div className="logo">
                                        <Link to="/" title="Party Richter bala">
                                            <img
                                                src={logo}
                                                alt=""
                                                title="Party Richter bala"
                                            />
                                        </Link>
                                    </div>
                                </div>

                                <div className="nav-box clearfix">
                                    <div className="nav-outer clearfix">
                                        <nav className="main-menu">
                                            <ul className="navigation clearfix">
                                                <li>
                                                    <Link to="/">
                                                        Startseite
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/#ueber-uns">
                                                        Über uns
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/buffet">
                                                        Buffet
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/a-la-carte">
                                                        Á la carte
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/stehconvent">
                                                        Stehconvent
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/fruehstueck">
                                                        Frühstück
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/kontakt">
                                                        Kontakt
                                                    </Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>

                                <div className="link link-btn">
                                    <Link
                                        to="/kontakt"
                                        className="theme-btn btn-style-one clearfix"
                                    >
                                        <span className="btn-wrap">
                                            <span className="text-one">
                                                Kontakt
                                            </span>
                                            <span className="text-two">
                                                Kontakt
                                            </span>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            )}
        </>
    );
}

export default Main;
