import React from "react";
import Bredcom from "../Bredcrumb/Main";
import Img from "../../assets/images/main-slider/slider-2.jpg";

function Main() {
    return (
        <>
            <Bredcom subtitle="Impressum" title="Rechtliches" Img={Img} />

            <div className="auto-container" style={{ margin: "120px auto" }}>
                <h2 id="m46">Diensteanbieter</h2>
                <p>Party Richter Bala</p>
                <p>Bahnhofstraße 70</p>
                <p>42781 Haan</p>

                <h2 id="m56">Kontaktmöglichkeiten</h2>
                <p>
                    E-Mail-Adresse:{" "}
                    <a href="mailto:info@party-richter-bala.de">
                        info@party-richter-bala.de
                    </a>
                </p>
                <p>
                    Telefon: <a href="tel:021293788003">02129 3788003</a>
                </p>

                <h2 id="m151">Register und Registernummer</h2>
                <p>Handelsregister</p>
                <p>Geführt bei: Amtsgericht Köln</p>
                <p>Nummer: HRB 1234</p>
            </div>
        </>
    );
}

export default Main;
