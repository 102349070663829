import React from "react";
import { Link } from "react-router-dom";
import Backgroundoffer from "../../assets/images/background/image-1b.jpg";
import recordbg1 from "../../assets/images/background/image-1b.jpg";
import recordbg2 from "../../assets/images/background/bg-4.png";
import recordbg3 from "../../assets/images/resource/badge-2.png";

function Specialdish() {
    return (
        <>
            <section className="special-dish">
                <div className="outer-container">
                    <div className="row clearfix">
                        <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="right-bg">
                                <img src={recordbg2} alt="" title="" />
                            </div>
                            <div
                                className="inner wow fadeInRight"
                                data-wow-duration="1500ms"
                                data-wow-delay="0ms"
                            >
                                <div className="title-box">
                                    <span className="badge-icon">
                                        <img src={recordbg3} alt="" title="" />
                                    </span>
                                    <div className="subtitle">
                                        <span>Unsere Spezialität</span>
                                    </div>

                                    <div className="pattern-image">
                                        <img
                                            src={
                                                require("../../assets/images/icons/separator.svg")
                                                    .default
                                            }
                                            alt="mySvgImage"
                                        />
                                    </div>

                                    <h2>BUFFET</h2>
                                    <div className="text">
                                        Unser Service kennt keine Grenzen.
                                        <br />
                                        <br />
                                        Wir bieten neben dem klassichen
                                        Mittagstisch auch Partyservice mit
                                        kulinarischen Köstlichkeiten an. Ganz
                                        gleich ob es die Familienfeier, die
                                        Bürofete oder das Jubiläum sein soll.
                                    </div>
                                </div>

                                <div className="link-box">
                                    <Link
                                        to="/buffet"
                                        className="theme-btn btn-style-two clearfix"
                                    >
                                        <span className="btn-wrap">
                                            <span className="text-one">
                                                Mehr erfahren
                                            </span>
                                            <span className="text-two">
                                                Mehr erfahren
                                            </span>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div
                                className="inner wow fadeInLeft"
                                data-wow-duration="1500ms"
                                data-wow-delay="0ms"
                            >
                                <div
                                    className="image-layer"
                                    style={{
                                        backgroundImage: `url(${Backgroundoffer})`,
                                    }}
                                ></div>
                                <div className="image">
                                    <img src={recordbg1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Specialdish;
