import React from "react";
import { Link } from "react-router-dom";
import bg19 from "../../../assets/images/background/bg-19.png";
import bg20 from "../../../assets/images/background/bg-20.png";
import menuimg5 from "../../../assets/images/resource/menu-image-5.png";
import menuimg6 from "../../../assets/images/resource/menu-image-6.png";
import menuimg7 from "../../../assets/images/resource/menu-image-7.png";
import menuimg8 from "../../../assets/images/resource/menu-image-8.png";
import menuimg9 from "../../../assets/images/resource/menu-image-9.png";
import menuimg10 from "../../../assets/images/resource/menu-image-10.png";

function Appetizers() {
    return (
        <>
            <section className="menu-two">
                <div className="left-bg">
                    <img src={bg20} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle">
                            <span>Das Zeichen für Geschmack</span>
                        </div>
                        <div className="pattern-image">
                            <img
                                src={
                                    require("../../../assets/images/icons/separator.svg")
                                        .default
                                }
                                alt="mySvgImage"
                            />
                        </div>
                        <h2>Das Kalte Buffet</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    100
                                                    <span className="s-info">
                                                        ab 15 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€16,75 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Matjestopf in Sahne
                                            <br />
                                            Pikante Geflügelfrikadelle, Senf
                                            <br />
                                            Kleine Putenschnitzel, Pfeffersauce
                                            <br />
                                            Gefüllter Rinderbraten
                                            <br />
                                            Krautsalat, Kartoffelsalat,
                                            Teufelssalat
                                            <br />
                                            Rustikales Käsebrett, Bauernbrotkorb
                                            <br />
                                            Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    101
                                                    <span className="s-info">
                                                        ab 10 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€17,40 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Matjescocktail
                                            <br />
                                            Poulardenbrust
                                            <br />
                                            Roastbeefröllchen, garniert mit
                                            Champignons
                                            <br />
                                            Eiersalat, Bauernsalat,
                                            Rindfleischsalat
                                            <br />
                                            Käseauswahl, Salzgebäck, Früchte
                                            <br />
                                            Brotauswahl, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    102
                                                    <span className="s-info">
                                                        ab 8 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€18,60 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Matjesröllchen mit Cornichons
                                            gefüllt
                                            <br />
                                            Gefülltes Ei mit Creme
                                            <br />
                                            Bunte Fleischplatte mit Rinderbraten
                                            <br />
                                            Putenbrust, reich garniert
                                            <br />
                                            mit Früchten und Mixed Pickles
                                            <br />
                                            Geflügelröllchen mit Spargel,
                                            Pfeffersauce
                                            <br />
                                            gefüllte Tomaten mit Waldorfsalat
                                            <br />
                                            Geflügelsalat, Eiersalat,
                                            Farmersalat
                                            <br />
                                            Internationale Käseauswahl,
                                            Weintrauben,
                                            <br />
                                            Salzgebäck, bunter Brotkorb, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    103
                                                    <span className="s-info">
                                                        ab 8 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€19,10 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Geräuchertes Makrelenfilet, Ei,
                                            gefüllt
                                            <br />
                                            mit Crevetten, Geflügelhappen
                                            <br />
                                            garniert mit Früchten
                                            <br />
                                            Roastbeef englisch, Spargelgarnitur
                                            <br />
                                            Saftiger Rinderbraten, gefüllter
                                            Pfirisch
                                            <br />
                                            Waldorfsalat, Tortellinisalat
                                            <br />
                                            Käseauswahl, Weintrauben, Salzbrezel
                                            <br />
                                            Früchtequarkcrem
                                            <br />
                                            Brotspezialität, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    104
                                                    <span className="s-info">
                                                        ab 10 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€26,30 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Lachstüte, gefüllt mit
                                            Preiselbeer-Sahne-Meerrettich,
                                            <br />
                                            Forellenfilet mit Oliven
                                            <br />
                                            Gef. Ei mi Crevetten
                                            <br />
                                            Geräucherte Putenbrust mit Ananas
                                            <br />
                                            Kalbsbraten mit Mixed Pickles
                                            <br />
                                            Geflügelröllchen mit Spargel
                                            <br />
                                            Geflügelsalat, Bauernsalat,
                                            Champignon-
                                            <br />
                                            salat, Französisches Käsebrett
                                            <br />
                                            Gefüllte Melone mit Obstsalat
                                            <br />
                                            Bunter Brotkorb, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    105
                                                    <span className="s-info">
                                                        ab 10 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€27,00 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Bunte Fischplatte mit Rauchlachs
                                            <br />
                                            Räucheraal, Matjesröllchen
                                            <br />
                                            Forellenfilet, garniert mit Ei,
                                            Zitrone
                                            <br />
                                            Sahnemeerrettich
                                            <br />
                                            Geflügelröllchen mit Spargel
                                            <br />
                                            Roastbeefröllchen mit Champignons
                                            <br />
                                            Gefüllte Birne mit Gervaise,
                                            garniert
                                            <br />
                                            Mastpoularde, mit Waldorfsalat
                                            gefüllt, garniert mit Pfirsischen,
                                            Ananas, Sauce Cumberland
                                            <br />
                                            Rindfleischsalat
                                            <br />
                                            Französische Käseauswahl,
                                            Weintrauben
                                            <br />
                                            Salzbrezel, Brotkorb Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    106
                                                    <span className="s-info">
                                                        ab 10 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€30,60 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Bunte Schwedenplatte mi
                                            Räucherlachs, Räucheraal,
                                            Forellenfilet
                                            <br />
                                            Matjesröllchen mit Preiselbeeren
                                            gefüllt
                                            <br />
                                            Crevetten in Sauce Tossi,
                                            Sahnemeerrettich
                                            <br />
                                            Poulardenbrust mit Fleischfüllung
                                            <br />
                                            Rinderfiletbraten medium mit Spargel
                                            <br />
                                            garniert, Pfeffersauce von frischem
                                            <br />
                                            grünem Pfeffer, Gervaise-Birnen
                                            <br />
                                            Obst und Champignons Pfanne
                                            <br />
                                            Drei verschiedene Salate nach Wahl
                                            <br />
                                            Bayerische Creme, Himbeersauce
                                            <br />
                                            Kleines Käsebrett, Weintrauben,
                                            Salzbrezel
                                            <br />
                                            Bunter Brotkorb, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    107
                                                    <span className="s-info">
                                                        ab 8 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€31,80 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Hummerkrabben, Cocktailsauce
                                            <br />
                                            Graved-Lachs mit Senfsauce
                                            <br />
                                            Geräucherter Heilbutt,
                                            Sahnemeerrettich
                                            <br />
                                            Gebackene Hühnerbrüstchen
                                            <br />
                                            Rinderfile medium, Saucen
                                            <br />
                                            Kalbsmedaillons mit Gemüsebouqet
                                            <br />
                                            Pfirsiche, mit Gervaise gefüllt
                                            <br />
                                            Eiersalat, Champignonsalat <br />
                                            Zitronencreme
                                            <br />
                                            Käsehappen, Brotkorb, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    108
                                                    <span className="s-info">
                                                        ab 10 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€41,40 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Grönland-Crevetten-Cockail
                                            <br />
                                            Bornholm-Lachs mit Sahnemeerrettich
                                            <br />
                                            Rehrücken, Sc. Cumberland
                                            <br />
                                            Roastbeef englisch, Sauce Tatar
                                            <br />
                                            Kalbsmedaillons mit Palmenherzen
                                            <br />
                                            Drei verschiedene Salat nach Wahl
                                            <br />
                                            Käsebrett mit Weintrauben
                                            <br />
                                            und Salzbrezeln
                                            <br />
                                            Gefüllte Birne mit Gervaise
                                            <br />
                                            Mohnmousse mit Himbeersauce
                                            <br />
                                            Bunter Brotkorb, Butter
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    109
                                                    <span className="s-info">
                                                        ab 10 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€40,20 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Pochiertes norwegisches
                                            Salmmedaillon
                                            <br />
                                            Crevetten und Hummercreme
                                            <br />
                                            Hummerkrabbenschwanz,
                                            <br />
                                            Cocktailsauce
                                            <br />
                                            Rinderfilet medium, im Blätterteig
                                            geb.
                                            <br />
                                            Gebackene Hühnerbrüstchen im
                                            Eimantel
                                            <br />
                                            Rindersteaks, mit Pfirsichen
                                            <br />
                                            garniert, Kräutersauce, Pfeffersauce
                                            <br />
                                            gefüllter Pfirsich mit Gervaise
                                            <br />
                                            Salatbuffet nach Wahl
                                            <br />
                                            Mousse au chocolat, mit Sahne
                                            garniert
                                            <br />
                                            Delikateß-Brotauswahl, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    110
                                                    <span className="s-info">
                                                        ab 10 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€32,30 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Melonenschiffchen mit
                                            Rinderschinken,
                                            <br />
                                            frische Kresse, Tomaten
                                            <br />
                                            Canapes mit hausgebeiztem Bornholm
                                            <br />
                                            Lachs und Forellenfillet, Dillcreme
                                            <br />
                                            Crevetteneier
                                            <br />
                                            Gefüllte Fasan, garniert mit
                                            Trauben, Ananas, Mandarinchen,
                                            <br />
                                            Sauce Cumberland
                                            <br />
                                            Kalbsmedaillons mit frischem Gemüse
                                            <br />
                                            Roastbeefbraten medium, Spargel
                                            <br />
                                            Waldorfsalat, Farmersalat
                                            <br />
                                            Käsefrivolitäten, Gervaise- Birnen
                                            <br />
                                            Heidelbeercreme, Brotkörbchen,
                                            Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    111
                                                    <span className="s-info">
                                                        ab 20 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€18,60 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Matjestopf in Sahne mit Äpfeln und
                                            Zwiebeln Forellenfilet
                                            <br />
                                            gef. Eier mit Crevetten
                                            <br />
                                            Hähnchenkeule,
                                            <br />
                                            Fleischwurst, Putenbraten
                                            <br />
                                            Hähnchenbraten, garniert mit Mixed
                                            <br />
                                            Pickles und Spargel, Pfeffersauce
                                            <br />
                                            Gefüllte Tomaten mit Waldorfsalat
                                            <br />
                                            Gervaise – Birnen
                                            <br />
                                            Nudelsalat mi Schminken, Bauernsalat
                                            <br />
                                            Mexicosalat, Farmersalat, Eiersalat,
                                            Kartoffelsalat
                                            <br />
                                            Käseauswahl, Salzbrezel
                                            <br />
                                            exotische Fruchtspeise
                                            <br />
                                            Brotauswahl, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    112
                                                    <span className="s-info">
                                                        ab 20 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€21,00 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Matjesröllchen mit Preiselbeeren
                                            gefüllt
                                            <br />
                                            Kieler Sprotten, gefüllte Eier mit
                                            Crevetten
                                            <br />
                                            Bunte Fleischplatten mit
                                            <br />
                                            Roasteef, Putenbraten
                                            <br />
                                            Früchten und Spargel
                                            <br />
                                            Hähnchenkeulen
                                            <br />
                                            Gefüllte Tomaten mit Fleischsalat
                                            <br />
                                            Gefüllte Gervais-Birnen
                                            <br />
                                            Spargel mit Ei
                                            <br />
                                            Geflügelsalat,
                                            Kidney-Bohnen-Maissalat
                                            <br />
                                            Bauernsalat, Farmersalat
                                            <br />
                                            Käseauswahl, Früchte, Salzgebäck
                                            <br />
                                            Delikateß-Brotauswahl, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    113
                                                    <span className="s-info">
                                                        ab 20 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€26,30 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Canapes mit Fischsorten
                                            <br />
                                            Wie echter Räucherlachs, geräucherte
                                            <br />
                                            Forellenfilets, geräucherter Aal,
                                            Matjes
                                            <br />
                                            Gefüllte Crevetteneier
                                            <br />
                                            Roastbeefröllchen mit Champignon
                                            <br />
                                            Gefüllte Gervais-Birnen
                                            <br />
                                            1 Mastpoularde „HAITA“, mit
                                            Waldorfsalat
                                            <br />
                                            gefüllt, mit Pfirsichen garniert
                                            <br />
                                            bunt garantiert Fleischplatten mit
                                            gebackener
                                            <br />
                                            Putenbrust, Kalbsbraten
                                            <br />
                                            Mixed Pickles mit Rinderbraten
                                            <br />
                                            Champignons, Spargel, Tomatenecken,
                                            Paprika
                                            <br />
                                            4 Salate nach Ihrer Wahl
                                            <br />
                                            franz, Käseauswahl, Früchte,
                                            Salzbrezel,
                                            <br />
                                            Delikateß-Brotauswahl, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    114
                                                    <span className="s-info">
                                                        ab 30 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€33,00 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Bunte Schwedenplatte mit Rauchlachs,
                                            <br />
                                            Räucheraal, Forellenfilets,
                                            Matjesröllchen
                                            <br />
                                            mit Preiselbeeren gefüllt,
                                            Sahnemeerrettich
                                            <br />
                                            garniert mit Eischeiben und Oliven,
                                            <br />
                                            gefüllte Tomaten mit Waldorfsalat
                                            <br />
                                            Gefüllte Gervaise-Birnen
                                            <br />
                                            Gefülltes Ei mit Currycreme
                                            <br />
                                            Rindermedaillons, mit Obst
                                            <br />
                                            garniert, kleine gebratene
                                            Hühnerbrüstchen
                                            <br />
                                            Kasseler im Blätterteig gebacken
                                            (kalt)
                                            <br />
                                            Pfeffersauce
                                            <br />
                                            1 Mastpoularde gefüllt mit
                                            <br />
                                            Waldorfsalat, mit Pfirsichen und
                                            Kirschen
                                            <br />
                                            garniert
                                            <br />
                                            Fleischplatten mit Roastbeef,
                                            <br />
                                            Hähnchenbraten, garniert mit
                                            <br />
                                            Mixed Pickles, Spargel, Champignons
                                            <br />
                                            5 Salate nach Ihrer Wahl
                                            <br />
                                            Französische Käseauswahl, garniert
                                            mit
                                            <br />
                                            Weintrauben, Salzbrezel, Früchten
                                            <br />
                                            Rote Grütze, Vanillesauce
                                            <br />
                                            Mousse au chocolat
                                            <br />
                                            Rustikale Brotauswahl, Butter
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    115
                                                    <span className="s-info">
                                                        ab 20 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€39,00 / Person</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Bunte Schwedenplatte mit Rauchlachs.
                                            <br />
                                            Räucheraal, Forellenfilets,
                                            Matjesröllchen
                                            <br />
                                            Mit Preiselbeeren gefüllt,
                                            Sahnemeerrettich
                                            <br />
                                            Crevetten in Sauce Tossi
                                            <br />
                                            Hirschkalbsrücken, garniert mit
                                            <br />
                                            Weintrauben, Ananas, Mandarinchen
                                            <br />
                                            Leberpastete, Sauce Cumberland
                                            <br />
                                            Filetmedaillons mit Obst garniert
                                            <br />
                                            Gebratene Hühnerbrüstchen im
                                            Eimantel
                                            <br />
                                            Gefüllte Gervaise-Birnen
                                            <br />
                                            Bunte Fleischplatten mit Roastbeef,
                                            <br />
                                            Kalbsbraten, reich garniert
                                            <br />
                                            4 Salate nach Ihrer Wahl
                                            <br />
                                            franz. Käseauswahl mit Weintrauben,
                                            <br />
                                            Salzbretzeln
                                            <br />
                                            Rote Grütze, Vanillesauce
                                            <br />
                                            Bayerische Crème, Fruchtsauce
                                            <br />
                                            Bunter Brotkorb, Kräuterbutter
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Appetizers;
