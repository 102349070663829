import React from "react";
import bg19 from "../../../assets/images/background/bg-19.png";

function Stehconvent() {
    return (
        <>
            <section className="menu-two">
                <div className="right-bg">
                    <img src={bg19} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle">
                            <span>Das Zeichen für Geschmack</span>
                        </div>
                        <div className="pattern-image">
                            <img
                                src={
                                    require("../../../assets/images/icons/separator.svg")
                                        .default
                                }
                                alt="mySvgImage"
                            />
                        </div>
                        <h2>Stehconvent</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>100 Cockailhappen</h5>
                                            </div>
                                            <div className="price">
                                                <span>€180,00</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit echtem Lachs, Forelle,
                                            Räucheraal, Roastbeef, Putenbrust,
                                            Kalbsbraten, Hühnerbrust,
                                            französische Käse o.ä., reich
                                            garniert mit Früchten, Mixed
                                            Pickles, Spargel, Champingons,
                                            Oliven
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>50 diverse Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€132,00</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit echtem Lachs, Räucheraal,
                                            Hühnerbrüstchen, Filetmedaillons,
                                            Heilbutt, Roasbeef, französischem
                                            Käse
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Eiercockail</h5>
                                            </div>
                                            <div className="price">
                                                <span>€5,40</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            In Senfsahne, Champignons
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>40 diverse Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€102,00</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Roastbeef, Kalbsbraten,
                                            Putenbrust, Lachs,
                                            Forelle,Französischer Käse, gefüllte
                                            Tomaten mit Waldorfsalat, garniert
                                            mit Früchten, Spargel und
                                            Champignons
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Bunter Käseigel</h5>
                                            </div>
                                            <div className="price">
                                                <span>€21,50</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            auf frischer Ananas, mit
                                            Salzbrezeln, Weintrauben (45 Picker)
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Bunter Käseigel</h5>
                                            </div>
                                            <div className="price">
                                                <span>€13,20</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            auf Orange
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3
                        id="cocktailhappen"
                        style={{
                            padding: "20px 10px",
                            textAlign: "center",
                        }}
                    >
                        Cockailhappen
                    </h3>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "4rem",
                        }}
                    ></div>

                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Cocktailhappen</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,00 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit echtem Lachs, Räucheraal,
                                            Forellenfilet, Crevetten,
                                            Hummerkrabben
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Cockailhappen</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,30 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Roastbeef, Hühnerbrust
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Cocktailhappen</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,30 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit französischen Käsesorten wie
                                            Pfefferkäse, Nußkäse, Roquefort,
                                            Camembert, Brie
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3
                        id="canapes"
                        style={{
                            padding: "20px 10px",
                            textAlign: "center",
                        }}
                    >
                        Canapes
                    </h3>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "4rem",
                        }}
                    ></div>

                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,45 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit echtem Lachs, Ei,
                                            Sahnemeerettich
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,75 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Räucheraal, Zitrone, Olive
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,10 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Forellenfilet, Sahnemeerettich,
                                            Ei
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,60 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Matjes, Preiselbeeren
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,50 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Crevetten, Sauce Tossi,
                                            Kaiserkirchen
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,80 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Hühnerbrüstchen, garniert mit
                                            Früchten
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,80 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Roastbeef, garniert mit
                                            Spargelspitzen
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,70 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Putenröllchen, garniert mit
                                            Mixed Pickles
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€4,99 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Rehmedaillon, Mandarinchen,
                                            Weintraube
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,80 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Putenröllchen, gefüllt mit
                                            Spargel
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,70 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Putenbrust, garniert m. Ananas
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,80 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Geflügel, garniert mit
                                            Cornichons od. Obst
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,60 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Truthan, garniert mit
                                            Mandarinchen
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,80 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit französischen Käsesorten
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,20 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Party-Shrimps, Cocktailsc
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Canapes</h5>
                                            </div>
                                            <div className="price">
                                                <span>€5,20 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Hummerkrabben
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3
                        id="warme-snacks"
                        style={{
                            padding: "20px 10px",
                            textAlign: "center",
                        }}
                    >
                        Warme Snacks
                    </h3>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "4rem",
                        }}
                    ></div>

                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Warme Snacks</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,40 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Kurpflaume mit Geflügel
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Warme Snacks</h5>
                                            </div>
                                            <div className="price">
                                                <span>€1,80 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Cocktailwürstchen i. Blätterteig
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Warme Snacks</h5>
                                            </div>
                                            <div className="price">
                                                <span>€6,60 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Garnelenschwänze in Dillrahm
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Warme Snacks</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,00 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Champignonköpfe, mit Schnecken
                                            gefüllt, Knoblauch-Kräuterbutter,
                                            pan.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Warme Snacks</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,70 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Truthanröllchen mit Blattspinat,
                                            Sauce Bearnaise
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Warme Snacks</h5>
                                            </div>
                                            <div className="price">
                                                <span>€1,80 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Geflügel Frikadelle mit Schalotten
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Warme Snacks</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,80 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            kleine Hühnerbrusthäppchen mit
                                            Picker
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Warme Snacks</h5>
                                            </div>
                                            <div className="price">
                                                <span>€5,70 / Stück</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Hummerkrabbe mit Picker
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3
                        id="getraenke"
                        style={{
                            padding: "20px 10px",
                            textAlign: "center",
                        }}
                    >
                        Getränke
                    </h3>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "2rem",
                        }}
                    ></div>

                    <p style={{ textAlign: "center" }}>auf Anfrage</p>

                    <h3
                        id="besteck-dekoration"
                        style={{
                            padding: "20px 10px",
                            textAlign: "center",
                            marginTop: "4rem",
                        }}
                    >
                        Besteck & Dekoration
                    </h3>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "2rem",
                        }}
                    ></div>

                    <p style={{ textAlign: "center" }}>auf Anfrage</p>
                </div>
            </section>
        </>
    );
}

export default Stehconvent;
