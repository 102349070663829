import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Slideone from "../../assets/images/main-slider/slider-1.jpg";
import Slidetwo from "../../assets/images/main-slider/slider-2.jpg";
import "swiper/swiper.min.css";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper.min.css";

SwiperCore.use([Navigation]);

function Banner() {
    return (
        <Swiper
            className="banner-section banner-section-coustm"
            loop={true}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }} // Use "navigation" with lowercase, and place it outside the SwiperSlide elements
        >
            <div className="banner-container">
                <div className="banner-slider">
                    <div className="swiper-wrapper">
                        {[Slidetwo, Slideone].map((image, index) => (
                            <SwiperSlide
                                key={index}
                                className="swiper-slide slide-item img-fluid"
                                style={{
                                    backgroundImage: `url(${image})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                }}
                            >
                                <div style={{ background: "rgba(0,0,0,.5)" }}>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle">
                                                            <span>
                                                                Herzlich
                                                                Willkommen!
                                                            </span>
                                                        </div>
                                                        <div className="pattern-image">
                                                            <img
                                                                src={
                                                                    require("../../assets/images/icons/separator.svg")
                                                                        .default
                                                                }
                                                                alt="mySvgImage"
                                                            />
                                                        </div>
                                                        <h1>
                                                            <span>
                                                                {index === 0 &&
                                                                    "Party Richter Bala"}
                                                                {index === 1 &&
                                                                    "Á la Carte & Buffet"}
                                                            </span>
                                                        </h1>
                                                        <div className="text">
                                                            Das Zeichen für
                                                            Geschmack
                                                        </div>
                                                        <div
                                                            className="links-box wow fadeInUp"
                                                            data-wow-delay="0ms"
                                                            data-wow-duration="1500ms"
                                                        >
                                                            <div
                                                                className="link"
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                    marginTop:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <Link
                                                                    to="/a-la-carte"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="theme-btn btn-style-two clearfix"
                                                                >
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">
                                                                            À la
                                                                            Carte
                                                                        </span>
                                                                        <span className="text-two">
                                                                            À la
                                                                            Carte
                                                                        </span>
                                                                    </span>
                                                                </Link>
                                                            </div>

                                                            <div
                                                                className="link"
                                                                style={{
                                                                    marginLeft:
                                                                        "10px",
                                                                    marginTop:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <Link
                                                                    to="/buffet"
                                                                    className="theme-btn btn-style-two clearfix"
                                                                >
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">
                                                                            Buffet
                                                                        </span>
                                                                        <span className="text-two">
                                                                            Buffet
                                                                        </span>
                                                                    </span>
                                                                </Link>
                                                            </div>

                                                            <div
                                                                className="link"
                                                                style={{
                                                                    marginLeft:
                                                                        "10px",
                                                                    marginTop:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <Link
                                                                    to="/stehconvent"
                                                                    className="theme-btn btn-style-two clearfix"
                                                                >
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">
                                                                            Stehconvent
                                                                        </span>
                                                                        <span className="text-two">
                                                                            Stehconvent
                                                                        </span>
                                                                    </span>
                                                                </Link>
                                                            </div>

                                                            <div
                                                                className="link"
                                                                style={{
                                                                    marginLeft:
                                                                        "10px",
                                                                    marginTop:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <Link
                                                                    to="/fruehstueck"
                                                                    className="theme-btn btn-style-two clearfix"
                                                                >
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">
                                                                            Frühstück
                                                                        </span>
                                                                        <span className="text-two">
                                                                            Frühstück
                                                                        </span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </div>
                </div>
            </div>
            <div className="swiper-button-prev">
                <span className="fal fa-angle-left"></span>
            </div>
            <div className="swiper-button-next">
                <span className="fal fa-angle-right"></span>
            </div>
            {/*<div className="book-btn">
                <Link to="#" className="theme-btn">
                    <span className="icon">
                        <img src={bookicon1} alt="" title="" />
                    </span>
                    <span className="txt">Kontakt</span>
                </Link>
                                                                </div>*/}
        </Swiper>
    );
}

export default Banner;
