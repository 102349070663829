import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Bgtwo from "../../assets/images/background/image-2.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import author1 from "../../assets/images/resource/author-thumb-1.jpg";
import author2 from "../../assets/images/resource/author-thumb-2.jpg";
import author3 from "../../assets/images/resource/author-thumb-3.jpg";
import SwiperCore, { Controller } from "swiper";

SwiperCore.use([Controller]);
function Contact() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [persons, setPersons] = useState("-");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const swiper1 = useRef(null);
    const swiper2 = useRef(null);

    const [flag, setflag] = useState(false);

    const slider2 = useRef();
    const duration = 500;
    const syncPosition = (e) => {
        if (!flag) {
            setflag(false);
            if (slider2.current) {
                slider2.current.to(e.item.index, duration);
            }
            setflag(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = { name, email, phone, persons, message };

        const res = await fetch(
            "https://rgfnmixxdkxfzwvgzyio.supabase.co/functions/v1/prb-email",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );

        if (res.ok) {
            setEmail("");
            setName("");
            setPhone("");
            setPersons("-");
            setMessage("");
            alert(
                "Ihre Nachricht wurde erfolgreich gesendet, wir werden uns in Kürze bei Ihnen melden."
            );
        } else {
            alert(
                "Ihre Nachricht konnte nicht gesendet werden, bitte versuchen Sie es erneut."
            );
        }
        setLoading(false);
    };

    return (
        <>
            <div className="testimonials-section">
                <div
                    className="image-layer"
                    style={{ backgroundImage: `url(${Bgtwo})` }}
                ></div>
                <div className="auto-container">
                    <div className="carousel-box owl-theme">
                        <Swiper
                            className="testi-top"
                            ref={swiper1}
                            controller={{ control: swiper2.current }}
                            items={1}
                            loop
                            margin={10}
                            autoplay
                            onChange={(e) => syncPosition(e)}
                        >
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">
                                        Wir haben letztes Jahr um die
                                        Weihnachtszeit ein ausgezeichnetes
                                        Gulasch bestellt. Meinen Gästen und mir
                                        hat es sehr gut geschmeckt. Ich konnte
                                        das Essen sogar an einem Sonntag Mittag
                                        abholen. Der Service nett und sehr
                                        zuvorkommend.
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">
                                        Haben für eine kleine Feier im Betrieb
                                        dort ein Buffet bestellt. Nach
                                        ausführlicher Beratung wurde auf den
                                        Punkt geliefert. Die Qualität der
                                        Speisen war SUPER. Alles warme war
                                        wirklich warm und die kalten Speisen,
                                        frisch und sehr liebevoll zubereitet.
                                        ABSOLUTE EMPFEHLUNG !!
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <div className="separator">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>

            <section id="kontakt" className="reserve-section">
                <div className="auto-container">
                    <div className="outer-box">
                        <div className="row clearfix">
                            <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>Anfrage</h2>
                                        <div className="request-info">
                                            Oder kontaktieren Sie uns direkt per
                                            Telefon:
                                            <Link to="#"> 02129 3788003</Link>
                                        </div>
                                    </div>
                                    <div className="default-form reservation-form">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row clearfix">
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            placeholder="Name *"
                                                            value={name}
                                                            onChange={(e) =>
                                                                setName(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            placeholder="E-Mail-Adresse *"
                                                            value={email}
                                                            onChange={(e) =>
                                                                setEmail(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <input
                                                            type="text"
                                                            name="phone"
                                                            placeholder="Telefonnummer"
                                                            value={phone}
                                                            onChange={(e) =>
                                                                setPhone(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <span className="alt-icon far fa-user"></span>
                                                        <select
                                                            className="l-icon"
                                                            value={persons}
                                                            onChange={(e) =>
                                                                setPersons(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <option value="-">
                                                                Anzahl der
                                                                Personen
                                                            </option>
                                                            <option>
                                                                1 - 5 Personen
                                                            </option>
                                                            <option>
                                                                5 - 10 Personen
                                                            </option>
                                                            <option>
                                                                10 - 20 Personen
                                                            </option>
                                                            <option>
                                                                20 - 30 Personen
                                                            </option>
                                                            <option>
                                                                30 - 40 Personen
                                                            </option>
                                                            <option>
                                                                50 - 75 Personen
                                                            </option>
                                                            <option>
                                                                75 - 100
                                                                Personen
                                                            </option>
                                                            <option>
                                                                100+ Personen
                                                            </option>
                                                        </select>
                                                        <span className="arrow-icon far fa-angle-down"></span>
                                                    </div>
                                                </div>

                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <div className="field-inner">
                                                        <textarea
                                                            name="message"
                                                            placeholder="Nachricht *"
                                                            value={message}
                                                            onChange={(e) =>
                                                                setMessage(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            required
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <div className="field-inner">
                                                        <button
                                                            type="submit"
                                                            className="theme-btn btn-style-one clearfix"
                                                            disabled={loading}
                                                        >
                                                            <span className="btn-wrap">
                                                                <span className="text-one">
                                                                    {loading
                                                                        ? "sendet..."
                                                                        : "Senden"}
                                                                </span>
                                                                <span className="text-two">
                                                                    {loading
                                                                        ? "sendet..."
                                                                        : "Senden"}
                                                                </span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="info-col col-lg-4 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>Kontakt</h2>
                                    </div>
                                    <div className="data">
                                        <div className="booking-info">
                                            <div className="bk-title">
                                                Telefonnummer
                                            </div>
                                            <div className="bk-no">
                                                <Link to="tel:02129 3788003">
                                                    02129 3788003
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="separator">
                                            <span></span>
                                        </div>
                                        <ul className="info">
                                            <li>
                                                <strong>Adresse</strong>
                                                <br />
                                                Bahnhofstraße 70, 42781 Haan
                                            </li>
                                            <li>
                                                <strong>
                                                    Büro-Öffnungszeiten
                                                </strong>
                                                <br />
                                                Montag - Samstag:
                                                <br />
                                                9:00 Uhr - 13:00 Uhr
                                            </li>
                                            <li>
                                                <strong>E-Mail-Adresse</strong>
                                                <br />
                                                <Link to="mailto:info@party-richter-bala.de">
                                                    info@party-richter-bala.de
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;
