import React, { useState } from "react";
import { Link } from "react-router-dom";
import bgone from "../../assets/images/background/bg-5.png";
import Bgtwo from "../../assets/images/background/bg-6.png";

function Menusection() {
    const [tabMenu, tabActive] = useState({
        a: true,
    });

    return (
        <>
            <section className="menu-section">
                <div className="left-bg">
                    <img src={bgone} alt="" title="" />
                </div>

                <div className="right-bg">
                    <img src={Bgtwo} alt="" title="" />
                </div>

                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle">
                            <span>Ausgewählte Speisen</span>
                        </div>

                        <div className="pattern-image">
                            <img
                                src={
                                    require("../../assets/images/icons/separator.svg")
                                        .default
                                }
                                alt="mySvgImage"
                            />
                        </div>

                        <h2>Menue's Individuell</h2>
                    </div>

                    <div className="tabs-box menu-tabs">
                        <div className="buttons">
                            <ul className="tab-buttons clearfix">
                                <li
                                    className={`tab-btn ${
                                        tabMenu.a && "active-btn"
                                    }`}
                                    onClick={() => tabActive({ a: true })}
                                    data-tab="#tab-1"
                                >
                                    <span>VORSPEISE</span>
                                </li>
                                <li
                                    className={`tab-btn ${
                                        tabMenu.b && "active-btn"
                                    }`}
                                    onClick={() => tabActive({ b: true })}
                                    data-tab="#tab-2"
                                >
                                    <span>SUPPE</span>
                                </li>
                                <li
                                    className={`tab-btn ${
                                        tabMenu.c && "active-btn"
                                    }`}
                                    onClick={() => tabActive({ c: true })}
                                    data-tab="#tab-3"
                                >
                                    <span>HAUPTGANG</span>
                                </li>
                                <li
                                    className={`tab-btn ${
                                        tabMenu.d && "active-btn"
                                    }`}
                                    onClick={() => tabActive({ d: true })}
                                    data-tab="#tab-4"
                                >
                                    <span>BEILAGE</span>
                                </li>
                                <li
                                    className={`tab-btn ${
                                        tabMenu.e && "active-btn"
                                    }`}
                                    onClick={() => tabActive({ e: true })}
                                    data-tab="#tab-5"
                                >
                                    <span>GEMÜSE BEILAGE</span>
                                </li>
                                <li
                                    className={`tab-btn ${
                                        tabMenu.f && "active-btn"
                                    }`}
                                    onClick={() => tabActive({ f: true })}
                                    data-tab="#tab-6"
                                >
                                    <span>SALATE NACH SAISON</span>
                                </li>
                                <li
                                    className={`tab-btn ${
                                        tabMenu.g && "active-btn"
                                    }`}
                                    onClick={() => tabActive({ g: true })}
                                    data-tab="#tab-7"
                                >
                                    <span>DESSERT</span>
                                </li>
                            </ul>
                        </div>
                        <div className="tabs-content">
                            <div
                                className={`tab ${tabMenu.a && "active-tab"}`}
                                id="tab-1"
                            >
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Leichte
                                                            Tomatencremesuppe
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>€5,40</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    Basilikum, Schlagrahm
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Geflügelpastete</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>€5,90</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Sauce Cumberland
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Gefüllte Avocados
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>€9,70</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Shrimps, Sauce Tossi
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Crevettencocktail
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>€10,20</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Mandarinchen, Sauce
                                                    Tossi
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Garnelen</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            Preis nach Saison
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit feiner Dillsauce
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Seezungenröllchen
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €10,70 / Person
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Blattspinat, Sauce
                                                    Veloute
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Hors-d’oevre Teller
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €18,00 / Person
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Lachs, Aalstückchen,
                                                    Forelle, gef. Ei mit
                                                    Crevetten, Seezungenröllchen
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`tab ${tabMenu.b && "active-tab"}`}
                                id="tab-2"
                            >
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Leichte
                                                            Tomatencremesuppe
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    Basilikum, Schlagrahm
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Oxtail Clair</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    Sherry, Chesterstange
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Consome double</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Fleischeinlag
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Champignoncremesuppe
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Zucchinicremesuppe
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Lauchcremesuppe</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Liaison
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Sauerampfercreme-Suppe
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`tab ${tabMenu.c && "active-tab"}`}
                                id="tab-3"
                            >
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Ochsenkeule</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €12,60 / Person
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    in Provinzkräutern, ab 20
                                                    Personen
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Züricher
                                                            Geschnetzeltes
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €14,10 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Rinderburgunderbraten
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €11,90 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Champignons
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Sauerbraten</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €16,20 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Rotkohl, Kartoffelklöße
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Glaciertes
                                                            Kalbsfrikandeau
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €18,60 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Rinderfiletbraten
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €23,40 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    (nur ab 10 Personen)
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Filetspitzen</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €22,20 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Pikante Lammkeule
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €16,20 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`tab ${tabMenu.d && "active-tab"}`}
                                id="tab-4"
                            >
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Petersilienkartoffeln
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Oxtail Clair</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    Sherry, Chesterstange
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Pellkartöffelchen
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Idaho Potatoes</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    Sauerrahm
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Kartoffelgratin</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Zucchini/Kartoffel-Gratin
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Patnareis</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Risi-Bisi</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Bunte Nudeln</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Spätzle</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Grüne Bandnudeln
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`tab ${tabMenu.e && "active-tab"}`}
                                id="tab-5"
                            >
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Frische Pfifferlinge
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Leipziger Allerlei
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Feine Zuckererbsen
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Zucchinigratin</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Gebundene Harico
                                                            verts
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Gelderländer Bauchspeck
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Champignons in
                                                            Kräutersauce
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Blattspinat</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Broccoli</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>auf Anfrage</span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    m. Mandelbutter
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Gemüsevariationen
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €7,00 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Ital.
                                                            Vorspeisenplatte
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €7,80 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Zucchini, Auberginen
                                                    etc.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`tab ${tabMenu.f && "active-tab"}`}
                                id="tab-6"
                            >
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Tomatensalat</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €2,70 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Gemischter Salat
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €2,70 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    French Dressing
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Radicchio Rosso</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €2,70 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    in Sahne
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Feldsalat</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €4,20 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Orangenstreifen, Italian
                                                    Dressing
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Eisbergsalat</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €3,00 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit „Thousand-Island-
                                                    Dressing”
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`tab ${tabMenu.g && "active-tab"}`}
                                id="tab-7"
                            >
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Obstsalat</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €4,00 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Rote Grütze</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €3,90 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Vanillesauce
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Pfirsich “Melba”
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €3,20 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Mousse au chocolat
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €3,60 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Mousse au chocolat
                                                            (weiß)
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €4,00 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Französische
                                                            Käseauswahl
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €4,80 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Kalte Zabaione</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €3,60 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Tiramisu</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €4,60 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Bayerische Crème
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €3,60 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Himbeersauce
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Mohnmousse-Cassis-Sauce
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €4,20 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Bananen-Kokosnuß-Crème
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €4,20 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>
                                                            Grießflammerie-Pfirsichsauce
                                                        </h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €3,60 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Malagacreme</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €4,00 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Cup Romanoff</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            Preis nach Saison
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Gefüllte Crepes</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €3,40 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc">
                                                    mit Früchtequark
                                                </div>
                                            </div>

                                            <div className="dish-block">
                                                <div className="title clearfix">
                                                    <div className="ttl clearfix">
                                                        <h5>Zitronencreme</h5>
                                                    </div>
                                                    <div className="price">
                                                        <span>
                                                            €3,60 / Portion
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text desc"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="open-timing">
                        <div className="hours">
                            Aus Liebe zum guten Essen bieten wir Ihnen täglich
                            ein ausgewogenes Angebot von traditionellenund
                            internationlen Gerichten an.
                        </div>
                        <div className="link-box">
                            <Link
                                to="/buffet"
                                className="theme-btn btn-style-two clearfix"
                            >
                                <span className="btn-wrap">
                                    <span className="text-one">
                                        Zur Buffetkarte
                                    </span>
                                    <span className="text-two">
                                        Zur Buffetkarte
                                    </span>
                                </span>
                            </Link>
                        </div>
                                </div>*/}
                </div>
            </section>
        </>
    );
}

export default Menusection;
