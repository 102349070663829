import React from "react";
import bg19 from "../../../assets/images/background/bg-19.png";

export default function Fruehstueck() {
    return (
        <>
            <section className="menu-two">
                <div className="right-bg">
                    <img src={bg19} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle">
                            <span>Das Zeichen für Geschmack</span>
                        </div>
                        <div className="pattern-image">
                            <img
                                src={
                                    require("../../../assets/images/icons/separator.svg")
                                        .default
                                }
                                alt="mySvgImage"
                            />
                        </div>
                        <h2>HALBE BELEGTE BRÖTCHEN 2.40€</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Spitzbrötchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Baguettebrötchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Kaiserbrötchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Vollwertbrötchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Kürbiskernbrötchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Roggenbrötchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Mohnbrötchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Weißbrot</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Graubrot</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3
                        id="belag"
                        style={{
                            padding: "20px 10px",
                            textAlign: "center",
                        }}
                    >
                        Belag
                    </h3>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "4rem",
                        }}
                    ></div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Bio Gouda</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Emmentaler</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Leerdamer</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Butterkäse</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Philadelphia Frischkäse
                                                    Natur
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Philadelphia Frischkäse
                                                    Kräuter
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Speisequark</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Vegetarischer Aufstrich</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Geflügelstreichwurst</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Teewurst</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Leberwurst Bauern Art</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Edelsalami Mailänder Art
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Geflügelsalami</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Gegrillte Putenbrust</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Räucherlachs</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Rührei</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Geköchtes Ei</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3
                        id="getraenke"
                        style={{
                            padding: "20px 10px",
                            textAlign: "center",
                        }}
                    >
                        Getränke
                    </h3>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "2rem",
                        }}
                    ></div>

                    <p style={{ textAlign: "center" }}>auf Anfrage</p>

                    <h3
                        id="besteck-dekoration"
                        style={{
                            padding: "20px 10px",
                            textAlign: "center",
                            marginTop: "4rem",
                        }}
                    >
                        Besteck & Dekoration
                    </h3>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "2rem",
                        }}
                    ></div>

                    <p style={{ textAlign: "center" }}>auf Anfrage</p>
                </div>
            </section>
        </>
    );
}
