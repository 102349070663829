import React from "react";
import Privateevent from "../Allmenus/Thrdmenu/Privateevent";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/background/banner-image-2.jpg";
import Contact from "../Menhome/Contact";
import Stehconvent from "../Allmenus/Thrdmenu/Stehconvent";

export default function StehconventPage() {
    return (
        <>
            <Bredcrumb
                subtitle="Stehconvent"
                title="Erstklassige Auswahl"
                Img={Img}
            />
            <Stehconvent />
            <Privateevent />
            <Contact />
        </>
    );
}
