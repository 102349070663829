import React from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import bg25 from "../../assets/images/background/bg-25.png";
import bg6 from "../../assets/images/background/bg-6.png";
import restro from "../../assets/images/resource/restaurant.png";
import Contact from "../Menhome/Contact";

function Contactbanner() {
    return (
        <>
            <div className="contact-map">
                <Iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2500.5459746516003!2d7.002768376718304!3d51.1905906337978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8d24d67e65de9%3A0xa1460afa6ffa6deb!2sParty%20Richter%20Bala!5e0!3m2!1sde!2sae!4v1696060606607!5m2!1sde!2sae"
                    width="100%"
                    height="500"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></Iframe>
            </div>

            <section className="contact-page">
                <div className="left-bg">
                    <img src={bg25} alt="" title="" />
                </div>
                <div className="right-bg">
                    <img src={bg6} alt="" title="" />
                </div>

                <Contact />
            </section>
        </>
    );
}

export default Contactbanner;
