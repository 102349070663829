import React from "react";
import { Link } from "react-router-dom";
import bg3 from "../../assets/images/background/bg-3.png";
import badge1 from "../../assets/images/resource/badge-1.png";
import img1 from "../../assets/images/resource/image-1.jpg";
import img2 from "../../assets/images/resource/image-2.jpg";

function Story() {
    return (
        <>
            <section className="story-section">
                <div className="left-bg">
                    <img src={bg3} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-xl-5 col-lg-5 col-md-12 col-sm-12">
                            <div
                                className="inner wow fadeInLeft"
                                data-wow-duration="1500ms"
                                data-wow-delay="0ms"
                            >
                                <div className="title-box centered">
                                    <div className="subtitle">
                                        <span>Unsere Geschichte</span>
                                    </div>
                                    <div className="pattern-image">
                                        <img
                                            src={
                                                require("../../assets/images/icons/separator.svg")
                                                    .default
                                            }
                                            alt="mySvgImage"
                                        />
                                    </div>
                                    <h2>Über uns</h2>
                                    <div className="text">
                                        Seit über 50 Jahren beliefert Party
                                        Richter Bala sämtliche Kunden mit
                                        frischen, leckeren und vor allem mit
                                        Liebe gemachten Delikatessen und
                                        versichert, dass das tägliche
                                        Mittagessen, die Feier oder das
                                        Firmen-Event zu einem Geschmackserlebnis
                                        wird. In unserem Á-LA-CARTE finden Sie
                                        das täglich angebotene Mittagessen,
                                        welches mitsamt fairen Preisen zu Ihnen
                                        nach Hause geliefert wird. Seit 2023
                                        arbeiten wir nahezu ausschließlich mit
                                        Mehrwegbehältern und streben auf ein
                                        umweltfreundlicheres Essen für die
                                        Zukunft hin.
                                    </div>
                                </div>
                                <div className="booking-info">
                                    <div className="bk-title">Kontakt</div>
                                    <div className="bk-no">
                                        <Link to="tel:021293788003">
                                            02129 3788003
                                        </Link>
                                    </div>
                                    {/*} <div className="link-box">
                                        <Link
                                            to="/"
                                            className="theme-btn btn-style-two clearfix"
                                        >
                                            <span className="btn-wrap">
                                                <span className="text-one">
                                                    Mehr erfahren
                                                </span>
                                                <span className="text-two">
                                                    Mehr erfahren
                                                </span>
                                            </span>
                                        </Link>
                                        </div>*/}
                                </div>
                            </div>
                        </div>

                        <div className="image-col col-xl-7 col-lg-7 col-md-12 col-sm-12">
                            <div
                                className="inner wow fadeInRight"
                                data-wow-duration="1500ms"
                                data-wow-delay="0ms"
                            >
                                <div className="images parallax-scene-1">
                                    <div className="image" data-depth="0.15">
                                        <img src={img1} alt="" />
                                    </div>
                                    <div className="image" data-depth="0.30">
                                        <img src={img2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Story;
