import React from "react";
import bg8 from "../../assets/images/background/bg-8.png";
import bg7 from "../../assets/images/background/bg-7.png";
import whyiconone from "../../assets/images/resource/why-icon-1.png";
import whyicontwo from "../../assets/images/resource/why-icon-2.png";
import whyiconthree from "../../assets/images/resource/why-icon-3.png";
import whyiconfour from "../../assets/images/resource/why-icon-4.png";

function Whyus() {
    const whyData = [
        {
            title: "Lieferung mit Mehrwegbehältern",
        },
        {
            title: "Abwechslungsreiche Gerichte",
        },
        {
            title: "365 Tage im Jahr geöffnet!",
        },
        {
            title: "Lieferung und Abholung möglich",
        },
        {
            title: "Täglich frisch zubereitet",
        },
    ];

    return (
        <>
            <section className="why-us">
                <div className="left-bg">
                    <img src={bg8} alt="" title="" />
                </div>
                <div className="right-bg">
                    <img src={bg7} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle">
                            <span>Warum Sie uns wählen sollten</span>
                        </div>
                        <div className="pattern-image">
                            <img
                                src={
                                    require("../../assets/images/icons/separator.svg")
                                        .default
                                }
                                alt="mySvgImage"
                            />
                        </div>

                        <h2>Unsere Stärken</h2>
                    </div>
                    <div className="" style={{ display: "flex" }}>
                        {whyData.map((data, index) => (
                            <div
                                key={index}
                                className="why-block"
                                style={{ flex: "1 0 0" }}
                            >
                                <div
                                    className="inner-box wow fadeInUp"
                                    data-wow-duration="1500ms"
                                    data-wow-delay={`${index * 300}ms`}
                                >
                                    <h4>{data.title}</h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Whyus;
