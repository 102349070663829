import React from "react";
import bg1 from "../../assets/images/background/bg-1.png";
import bg2 from "../../assets/images/background/bg-2.png";
import canapes from "../../assets/images/resource/canapes.jpg";
import offerimg2 from "../../assets/images/resource/offer-image-2.jpg";
import cocktailhappen from "../../assets/images/resource/cocktailhappen.jpg";
import kristallglas from "../../assets/images/resource/kristallglas.jpg";
import { HashLink } from "react-router-hash-link";

function Offer() {
    const offers = [
        {
            image: canapes,
            title: "Canapés",
            link: "/buffet#canapes",
        },
        {
            image: offerimg2,
            title: "Warme Snacks",
            link: "/buffet#warme-snacks",
        },
        {
            image: cocktailhappen,
            title: "Cocktailhappen",
            link: "/buffet#cocktailhappen",
        },
        {
            image: kristallglas,
            title: "Snacks im Kristallglas",
            link: "/buffet#snacks-im-kristallglas",
        },
    ];

    return (
        <>
            <section className="we-offer-section">
                <div className="left-bot-bg">
                    <img src={bg1} alt="" title="" />
                </div>
                <div className="right-top-bg">
                    <img src={bg2} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle">
                            <span>Fingerfood</span>
                        </div>
                        <div className="pattern-image">
                            <img
                                src={
                                    require("../../assets/images/icons/separator.svg")
                                        .default
                                }
                                alt="mySvgImage"
                            />
                        </div>
                        <h2>Stehconvent</h2>
                        <div className="text">
                            Das perfekte Fingerfood für die unterschiedlichsten
                            Präferenzen.
                        </div>
                    </div>
                    <div className="row justify-content-center clearfix">
                        {offers.map((offer, index) => (
                            <div
                                key={index}
                                className="offer-block col-xl-3 col-lg-3 col-md-6 col-sm-12"
                            >
                                <div
                                    className="inner-box wow fadeInUp"
                                    data-wow-duration="1500ms"
                                    data-wow-delay={`${index * 300}ms`}
                                >
                                    <div className="image">
                                        <HashLink to={offer.link}>
                                            <img src={offer.image} alt="" />
                                        </HashLink>
                                    </div>
                                    <h3>
                                        <HashLink to={offer.link}>
                                            {offer.title}
                                        </HashLink>
                                    </h3>
                                    <div className="more-link">
                                        <HashLink to={offer.link}>
                                            Zum Menü
                                        </HashLink>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Offer;
