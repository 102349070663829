import React from "react";
import bg19 from "../../../assets/images/background/bg-19.png";

function CocktailsImKristallGlas() {
    return (
        <>
            <section id="snacks-im-kristallglas" className="menu-two">
                <div className="right-bg">
                    <img src={bg19} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle">
                            <span>Das Zeichen für Geschmack</span>
                        </div>
                        <div className="pattern-image">
                            <img
                                src={
                                    require("../../../assets/images/icons/separator.svg")
                                        .default
                                }
                                alt="mySvgImage"
                            />
                        </div>
                        <h2>Cocktails im Kristallglas serviert</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Shrimpscocktail</h5>
                                            </div>
                                            <div className="price">
                                                <span>€9,00</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Champignons, Ananas
                                            Cocktailsauce
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Crevetten Cocktail</h5>
                                            </div>
                                            <div className="price">
                                                <span>€10,20</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Geflügelcocktail “Indisch”
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€7,10</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Mango, Früchten, Cürrysahne
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Matjescocktail</h5>
                                            </div>
                                            <div className="price">
                                                <span>€7,40</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Sahne, Äpfeln, Zwiebel
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Eiercockail</h5>
                                            </div>
                                            <div className="price">
                                                <span>€5,40</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            In Senfsahne, Champignons
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Champignoncocktail</h5>
                                            </div>
                                            <div className="price">
                                                <span>€6,20</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            in Vinaigrette, Cocktailsauce
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Hummercocktail</h5>
                                            </div>
                                            <div className="price">
                                                <span>auf Anfrage</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Ananas, Champignons,
                                            Cocktailsauce
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CocktailsImKristallGlas;
