import React from "react";
import bg19 from "../../../assets/images/background/bg-19.png";

function DasMenuIndividuell() {
    return (
        <>
            <section className="menu-two">
                <div className="right-bg">
                    <img src={bg19} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle">
                            <span>Das Zeichen für Geschmack</span>
                        </div>
                        <div className="pattern-image">
                            <img
                                src={
                                    require("../../../assets/images/icons/separator.svg")
                                        .default
                                }
                                alt="mySvgImage"
                            />
                        </div>
                        <h2>Das Buffet Individuell</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Lachstüte</h5>
                                            </div>
                                            <div className="price">
                                                <span>€4,70</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Preiselbeer – Sahnemeerrettich
                                            gefüllt
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Salmscheibe</h5>
                                            </div>
                                            <div className="price">
                                                <span>€8,10</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            „Ludwig“, mit Weintrauben Garniert,
                                            Eicreme, Crevetten
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Gefülle Arischockenherzen
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€4,20</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Crevetten
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Gefüllte Tomate</h5>
                                            </div>
                                            <div className="price">
                                                <span>€1,90</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Waldorfsalat
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Gefüllte Tomate</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,04</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Spargel
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Gefüllte Ei</h5>
                                            </div>
                                            <div className="price">
                                                <span>€1,70</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Nordseekrabben
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Gefüllte Williamsbirne mit
                                                    Gervais-Creme
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,20</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            reich garniert mi Früchten
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Gefüllte Birne mit Roquefort
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,20</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            reich garniert mit Früchten
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Gefüllte Floridapfirsiche
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,50</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Gervais
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Melone mit Rinderschinken
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€5,40</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Gebackene Hühnerbrüstchen
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,80</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            „Wiener Art“
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Putenröllchen</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,70</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Spargel gefüllt
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Hähnchenbrust</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,30</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Ananas und Mandarinen
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Medaillon vom Hähnchenfilet
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€4,50</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Champignons, Lebermus
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Rindersteak</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,90</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Pfirsichen und Mandarinen
                                            garniert
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Lammsteak</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,90</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Spargel und Champignons garniert
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Roastbeefröllchen</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,50</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Champignons gefüllt
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Tournedos (Rinderfilet)</h5>
                                            </div>
                                            <div className="price">
                                                <span>€6,60</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Pfirsischen
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Kalbsmedaillons</h5>
                                            </div>
                                            <div className="price">
                                                <span>€6,60</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Champignons garniert
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Rehmedaillon</h5>
                                            </div>
                                            <div className="price">
                                                <span>€6,60</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Fasanenbrüstchen</h5>
                                            </div>
                                            <div className="price">
                                                <span>€6,60</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Frikadelle</h5>
                                            </div>
                                            <div className="price">
                                                <span>€1,80</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Hähnchenschnitzel</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,70</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Hähnchenkeule</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,60</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Halbe belegte Brötchen</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,90</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit guten Fleisch- Käsesorten a
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Handschnittchen</h5>
                                            </div>
                                            <div className="price">
                                                <span>€2,90</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit guten Fleisch- und Käsesorten a
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "4rem",
                        }}
                    ></div>

                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Putenfilet</h5>
                                            </div>
                                            <div className="price">
                                                <span>€22,60</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Mit Gemüse, Mixed Pickles, Tomaten
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Roastbeef</h5>
                                            </div>
                                            <div className="price">
                                                <span>€36,00</span>
                                            </div>
                                        </div>
                                        <div className="text desc">500 g</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Rinderfiletbraten</h5>
                                            </div>
                                            <div className="price">
                                                <span>€43,10</span>
                                            </div>
                                        </div>
                                        <div className="text desc">500 g</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Putenbrust</h5>
                                            </div>
                                            <div className="price">
                                                <span>€26,40</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            500 g - reich garniert mit Früchten,
                                            Weintrauben Mandarinen
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Kalbsbraten</h5>
                                            </div>
                                            <div className="price">
                                                <span>€37,80</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            500 g - reich garn. Mit Gemüse
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Bunte Westfalenplatte</h5>
                                            </div>
                                            <div className="price">
                                                <span>€25,80</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            500 g - Bunte Platte mit Pute,
                                            Hähnchen und Roastbeef
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Putenbraten</h5>
                                            </div>
                                            <div className="price">
                                                <span>€20,00</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            500 g - sehr mager, garniert mit
                                            Mixed Pickles und Tomaten
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Bunte Platte mit Roastbeef,
                                                    Hähnchenbraten
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€24,60</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            500 g - Roastbeef, Kalbsbraten,
                                            gegrillte Putenbrust, garniert mit
                                            Spargel, Champignons Mixed Pickles
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Rinnderfilet</h5>
                                            </div>
                                            <div className="price">
                                                <span>€30,00</span>
                                            </div>
                                        </div>
                                        <div className="text desc">500 g</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "4rem",
                        }}
                    ></div>

                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Bunte Schwedenplatte
                                                    <span className="s-info">
                                                        ab 5 Personen
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€16,20 / Portion</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Lachs, Räucheraal, Heilbutt,
                                            Forellenfilet, Crevetteneier,
                                            Matjesröllchen reich garniert mit
                                            Zitronenscheiben, Oliven,
                                            Sahnemerretich
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Geräucherte Nordseeaal</h5>
                                            </div>
                                            <div className="price">
                                                <span>€14,30 / Portion</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mi Ei, schwarze Oliven
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Portion Räucherlachs</h5>
                                            </div>
                                            <div className="price">
                                                <span>€14,30 / Portion</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Sahnemeerrettich, Zitrone Ei, Oliven
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Geräuchertes Forellenfilet
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€7,80 / Portion</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            reich garniert mit Tomaten, Ei
                                            Sahnemeerrettich
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Matjesröllchen</h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,30 / Portion</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Preiselbeer-Sahne- Meerrettich
                                            gefüllt
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Frischer Hummer „Parisienne“
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>auf Anfrage</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Pariser Salat gefüllt, Sauce
                                            Tossi reich garniert
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "4rem",
                        }}
                    ></div>

                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Walddorfsalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€9,20</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            in Sahne
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Eiersalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€8,10</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Champignons, Ananas, Spargel
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Indonesischer Reissalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€8,00</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Teufelssalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€10,50</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Rindfleisch, Perlzwiebel
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Geflügelsalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€11,40</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Spargel, Champignons
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Entenbrust</h5>
                                            </div>
                                            <div className="price">
                                                <span>€20,10</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Zuckerschote
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Mexicosalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€9,30</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Mais, Paprika
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Eiersalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€8,10</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Champignons, Ananas, Spargel
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Champignonsalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€11,40</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            von ganzen Köpfen Gürkchen
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Spargelsalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€11,40</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            in Sauce Vinaigrette
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Diplomatensalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€11,40</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Rinderschinken, Eier, Spargel,
                                            Erbsen
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Crevettensalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€19,80</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>
                                                    Portion frischer Salat der
                                                    Saison
                                                </h5>
                                            </div>
                                            <div className="price">
                                                <span>€3,50</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit French Dressing
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Bauernsalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€8,90</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Rohkost in Creme
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Majes-Topf</h5>
                                            </div>
                                            <div className="price">
                                                <span>€10,70</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Sahne, Äpfel, Zwiebel
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Putensalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€10,50</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Spargel, Champignons, Ananas‚
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Farmersalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€8,90</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Sellerie, Porree, Möhren
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Rindfleischsala</h5>
                                            </div>
                                            <div className="price">
                                                <span>€10,40</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            Tomate, Spargel
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Kartoffelsalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€8,30</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Nudelsalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€7,60</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Krautsalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€6,90</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Pfifferling-Lauch-Salat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€14,10</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Griechischer Salat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€11,90</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Schafskäse
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Kidneybohnen/Maissalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€8,20</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Zucchinisalat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€14,70</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Krabben und Rosinen
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Apfel-Lauch-Salat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€8,30</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Mozzarella-Salat</h5>
                                            </div>
                                            <div className="price">
                                                <span>€9,20</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            mit Tomaten
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            height: "1px",
                            background: "#fff",
                            marginBottom: "4rem",
                        }}
                    ></div>

                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Party-Teller</h5>
                                            </div>
                                            <div className="price">
                                                <span>auf Anfrage</span>
                                            </div>
                                        </div>
                                        <div className="text desc">
                                            gemischt Oder Roggenbrot
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className=".inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                                <h5>Bunter Brotkorb</h5>
                                            </div>
                                            <div className="price">
                                                <span>auf Anfrage</span>
                                            </div>
                                        </div>
                                        <div className="text desc">Butter</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DasMenuIndividuell;
